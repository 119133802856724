
import Checkbox from '@/UI/checkbox'
import axios from '@/axios'
import { Ref, ref } from '@vue/runtime-core'
import { DefaultInput, DefaultTable } from 'apptimizm-ui'
import { DefaultTableExposed, TableHeader } from 'apptimizm-ui/src/ui/default-table/default-table'
import { ISelectValue } from '../projects/types'
import { Employee, EmployeeMeta } from './types'

interface TableFilter {
  readonly tableParams: {
    [code: string]: string
  }
}

interface ITableProps {
  table: Ref<DefaultTableExposed>
  tableFilter: TableFilter
  roles: ISelectValue[]
  isAutoAssignment: boolean
}

export default ({ table, tableFilter, roles, isAutoAssignment }: ITableProps) => {
  const employeeMeta = new EmployeeMeta()

  const newName = ref('')

  const userId = ref('')

  const changeName = async (item: Employee) => {
    if (newName.value === '' || item.id !== userId.value) return

    const roles = item.roles.map(val => val.value)
    item.username = newName.value

    await employeeMeta.update(item.id, { roles, username: newName.value })

    newName.value = ''
    userId.value = ''
  }

  const markIsCheck = async (item: Employee, role: ISelectValue) => {
    if (item.roles.some(x => x.value === role.value)) {
      item.roles = item.roles.filter(x => x.value !== role.value)
      const roleMin = item.roles.map(val => val.value)

      await employeeMeta.update(item.id, { roles: roleMin })
    } else {
      item.roles.push(role)
      const rolePlus = item.roles.map(val => val.value)

      await employeeMeta.update(item.id, { roles: rolePlus })
    }
  }

  async function updateIsAutoAssignment (employee: Employee) {
    const { id, roles, isAutoAssignment } = employee

    await employeeMeta.update(id, {
      roles: roles.map(role => role.value),
      is_auto_assignment: !isAutoAssignment
    })
    employee.isAutoAssignment = !isAutoAssignment
  }

  const headers: TableHeader[] = [
    { name: 'ID', sort: 'serial_number', minWidth: '100px' },
    { name: 'Пользователи', sort: 'username', minWidth: '200px' },
    { name: 'Роли ', sort: 'roles', minWidth: '324px' },
    { name: 'Норма', sort: 'norm', minWidth: '106px' },
    { name: 'Счетчик', sort: 'counter_scan', minWidth: '112px' },
    { name: 'УК', sort: 'controller', minWidth: '96px' },
    { name: 'Внешний аудитор', sort: 'auditor_external', minWidth: '96px' },
    { name: 'Аудитор УК', sort: 'auditor', minWidth: '112px' },
    { name: 'Аудитор', sort: 'auditor_controller', minWidth: '96px' },
    { name: 'Сотрудник склада', sort: 'storage', minWidth: '136px' }
  ]

  const line = (item: Employee) => {
    return (
      <tr style={item.isDeleted ? 'display: none;' : ''}>
        <td>{item.serialNumber}</td>
        <td onClick={() => { userId.value = item.id }}>
          <div class="username-wrapper">
            <div class="td-warapper">
              {isAutoAssignment && <Checkbox
                modelValue={item.isAutoAssignment}
                onClick={(e: MouseEvent) => { e.stopPropagation(); updateIsAutoAssignment(item) }}
              />}
              {isAutoAssignment && <div class="table-hover">{item.isAutoAssignment ? 'Исключить из автоназначения' : 'Добавить в автоназначение'}</div>}
              <div>{item.username}</div>
            </div>
            { userId.value === item.id && <DefaultInput
              class={`${item.id === userId.value ? 'show-me' : 'hide-me'} fix-input`}
              modelValue={newName.value}
              placeholder={item.username}
              constantPlaceholder={false}
              onValueChange={(v: string) => { newName.value = v }}
              onBlur={() => { changeName(item) }}
            />
            }
          </div>
        </td>
        <td class="row hover">
          <span>{employeeMeta.getRoles(item.roles)}</span>
          <div class="table-hover">
            <p>Изменить роль</p>
            { roles.map((role) =>
              <div>
                <Checkbox modelValue={item.roles.some(x => x.value === role.value)} onClick={() => markIsCheck(item, role)} placeholder={role.name} />
              </div>
            )}
          </div>
        </td>
        <td>{item.norm}</td>
        <td>{item.counterScan}</td>
        <td>{item.counterController}</td>
        <td>{item.auditorExternal}</td>
        <td>{item.auditorScan}</td>
        <td>{item.auditorController}</td>
        <td>{item.storage}</td>
      </tr>
    )
  }

  return (
    <div class='table-wrapper'>
      <DefaultTable
        axios={axios}
        endpoint={employeeMeta.endpoint}
        defaultFilter={tableFilter.tableParams}
        ref={table}
        line={line}
        headers={headers}
        itemConverter={(v: Employee) => employeeMeta.load(v)}
        responseItemsKey="results"
        responseTotalKey="count"
        paginationType="page"
        scrollPagination={true}
        perPage={20}
      />
    </div>
  )
}
