import axios from '@/axios'
import { host } from '@/const'
import { reactive } from 'vue'

export const progressStore = reactive({
  progresId: '',

  percent: 0,

  reset () {
    this.progresId = ''
    this.percent = 0
  }
})

export const getCeleryTask = async (celeryId: string, timeout = 3000, progresId = ''): Promise<any> => {
  if (!celeryId) return
  return new Promise((resolve, reject) => {
    const interval = setInterval(async () => {
      let response = null
      try {
        response = await axios.get(`${host}/api/v1/celery/result/${celeryId}`)
        if (progresId) {
          progressStore.progresId = progresId
          progressStore.percent = response?.data?.result?.percent || 0
        }
      } catch (error) {
        clearInterval(interval)
        progressStore.reset()
        return reject(response)
      }
      if (response.data.status === 'SUCCESS') {
        clearInterval(interval)
        progressStore.reset()
        return resolve(response)
      }
      if (response.data.status === 'FAILURE') {
        clearInterval(interval)
        progressStore.reset()
        return reject(response)
      }
    }, timeout)
  })
}
