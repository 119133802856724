interface LoaderProps {
  hidden: boolean
}

export const ButtonLoader = ({ hidden }: LoaderProps): JSX.Element => {
  return (
    <>
      {hidden
        ? null
        : <img
          class='button-loader'
          src={require('../assets/image/loader.svg')}
        />
      }
    </>
  )
}
