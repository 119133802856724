import { RadioButton } from 'apptimizm-ui'

interface CheckboxProps {
  modelValue: boolean
  placeholder?: string
  onClick: (e: MouseEvent) => void
}

export default ({ modelValue, placeholder }: CheckboxProps) => {
  return (
    <div class="checkbox-item">
      <RadioButton modelValue={modelValue}/>
      <span>{placeholder}</span>
    </div>
  )
}
